<template>
  <v-app>
    <v-main>
      <loading v-if="loading" />
      <app-container v-if="login" @loginChange="onLoginChange" @loadingStateChange="onLoadingStateChange" />
      <login @loginChange="onLoginChange" @loadingStateChange="onLoadingStateChange" v-else />
    </v-main>
  </v-app>
</template>

<script>
import Loading from './components/Addons/Loading.vue'
import Login from './views/Login.vue'
import AppContainer from './views/AppContainer.vue'

export default {
  components: {
    Loading,
    Login,
    AppContainer
  },
  name: 'App',
  data () {
    return {
      loading: false,
      login: this.$ls.get('user_data') !== null
    }
  },
  methods: {
    onLoadingStateChange (newState) {
      this.loading = newState
    },
    onLoginChange () {
      console.log(this.$ls.get('user_data'))
      this.login = this.$ls.get('user_data') !== null
    }
  },
  created () {
    document.title = process.env.VUE_APP_TITLE
  //   this.$ls.set('user_data', null)
  }
}
</script>
