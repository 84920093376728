export default axios => ({

  get (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/plannedTime?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
      console.log(response)
      callback()
    })
  },

  getByUser (userId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/plannedTime?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
      console.log(response)
      callback()
    })
  },

  add (userId, departmentId, startTime, endTime, description, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {
      start_time: startTime,
      end_time: endTime,
      description: description
    }

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/department/' + departmentId + '/plannedTime',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (plannedTimeId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/plannedTime/' + plannedTimeId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (plannedTimeId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/plannedTime/' + plannedTimeId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getWeeklyHours (userId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/plannedTime/weeklyHours?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
    })
  },

  getDepartmentHours (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/plannedTime/hours?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
    })
  },

  moveUserTimesToNextWeek (userId, departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/department/' + departmentId + '/plannedTime/moveToNextWeek?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
    })
  },

  moveDepartmentTimesToNextWeek (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/plannedTime/moveToNextWeek?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
    })
  },

  downloadPEP (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const url = process.env.VUE_APP_API_HOST + 'department/' + departmentId + '/pep-export?startDate=' + startDate + '&endDate=' + endDate
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'pep-export.pdf')
    document.body.appendChild(link)
    link.click()
  },

  getDepartmentPublishedUntil (departmentId, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/timesPublishedUntil?endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
      console.log(response)
      callback()
    })
  },

  publishPlannedTimes (departmentId, date, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/publishPlan',
      {
        date: date
      }
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
