export default axios => ({

  getByUser (userId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/absence?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
      console.log(response)
      callback()
    })
  },

  getByDepartment (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/absence?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch((response) => {
      console.log('Error')
      console.log(response)
      callback()
    })
  },

  add (userId, absenceTypeId, startDate, endDate, description, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {
      startDate: startDate,
      endDate: endDate,
      absenceTypeId: absenceTypeId
      // description: description
    }

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/absence',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (absenceId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/absence/' + absenceId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (absenceId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/absence/' + absenceId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
