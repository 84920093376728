import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/logout',
    name: 'LogoutView',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/personnel-administration',
    name: 'PersonnelAdministrationView',
    component: () => import('../views/PersonnelAdministration/PersonnelAdministration.vue')
  },
  {
    path: '/personnel-administration/:userId',
    name: 'PersonnelAdministrationProfileView',
    component: () => import('../views/PersonnelAdministration/Profile.vue'),
    props: true
  },
  {
    path: '/my-schedule',
    name: 'MyScheduleView',
    component: () => import('../views/MySchedule.vue')
  },
  {
    path: '/department-administration',
    name: 'DepartmentAdministrationView',
    component: () => import('../views/DepartmentAdministration/DepartmentAdministration.vue')
  },
  {
    path: '/work-schedule/:departmentId?',
    name: 'WorkScheduleAdministrationView',
    component: () => import('../views/WorkSchedule/WorkSchedule.vue')
  },
  {
    path: '/absences/:departmentId?',
    name: 'AbsencesAdministrationView',
    component: () => import('../views/Absences/Absences.vue')
  },
  {
    path: '/news',
    name: 'NewsView',
    component: () => import('../views/News/News.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
