<template>
  <v-container
    id="app_container"
  >
    <Menu />
    <section id="router_view_container">
      <profile-top-bar/>
      <router-view />
    </section>
  </v-container>
</template>

<script>
import * as departmentStore from '@/utils/globalStore'

import Menu from '../components/Menu/Menu.vue'
import ProfileTopBar from '../components/Header/ProfileTopBar.vue'
export default {
  name: 'AppContainer',
  components: {
    Menu,
    ProfileTopBar
  },
  methods: {
    onLoadingStateChange (newState) {
      this.$emit('loadingStateChange', newState)
    },
    onLoginChange () {
      this.$emit('loginChange')
    }
  },
  created () {
    departmentStore.setApiToken(this.$ls.get('user_data').api_token)
    departmentStore.loadDepartments()
    departmentStore.loadAbsenceTypes()
    departmentStore.loadHolidays(departmentStore.year.value)
  }
}
</script>
@/utils/globalStore.js
