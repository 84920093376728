import { ref, watch } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import Department from '@/api/department.js'
import AbsenceType from '@/api/absenceType.js'
import Holiday from '@/api/holiday'
import PlannedTime from '@/api/plannedTimes'

dayjs.locale('de')
dayjs.extend(weekOfYear)

const departmentApi = Department(axios)
const absenceTypeApi = AbsenceType(axios)
const holidayApi = Holiday(axios)
const plannedTimeApi = PlannedTime(axios)

export const apiToken = ref(null)

export function setApiToken (newApiToken) {
  apiToken.value = newApiToken
}

export const departmentsLoading = ref(false)

export const departments = ref([])

export const department = ref(null)

export function loadDepartments () {
  if (departments.length > 0 || departmentsLoading.value) return

  departmentsLoading.value = true
  departmentApi.getAll(apiToken.value, (response) => {
    if (response.status === 200) {
      departments.value = response.data
      departmentsLoading.value = false
    }
  })
}

export const absenceTypesLoading = ref(false)

export const absenceTypes = ref([])

export function loadAbsenceTypes () {
  if (absenceTypes.length > 0 || absenceTypesLoading.value) return

  absenceTypesLoading.value = true
  absenceTypeApi.getAll(apiToken.value, (respone) => {
    absenceTypes.value = respone.data
    absenceTypesLoading.value = false
  })
}

export const year = ref(dayjs().year())
export const week = ref(dayjs().week())
export const month = ref(dayjs().month() + 1)

export const holidays = ref([])

export function loadHolidays (year) {
  holidayApi.get(`${year}-01-01`, `${year}-12-31`, apiToken.value, (respone) => {
    holidays.value = respone.data
  })
}

watch(year, (year) => {
  loadHolidays(year)
}, { deep: true })

export const departmentWorkingHours = ref([])
export const departmentWorkingHoursLoading = ref(false)

export function loadDepartmentWorkingHours () {
  if (departmentWorkingHoursLoading.value || department.value == null) return

  departmentWorkingHoursLoading.value = true

  plannedTimeApi.getDepartmentHours(department.value, `${year.value}-${month.value}-01`, `${year.value}-${month.value}-31`, apiToken.value, (respone) => {
    departmentWorkingHours.value = respone.data
    departmentWorkingHoursLoading.value = false
  })
}

watch([month, department], () => {
  loadDepartmentWorkingHours()
})
