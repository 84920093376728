export default axios => ({

  get (userId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/userData').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  add (userId, userDataTypeId, value, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.user_data_type_id = userDataTypeId
    data.value = value

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/userData',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (userId, userDataId, userDataTypeId, value, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.user_data_type_id = userDataTypeId
    data.value = value

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/userData/' + userDataId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (userId, userDataId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/userData/' + userDataId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
