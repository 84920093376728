export default axios => ({
  login (email, password, success, error) {
    axios.post(process.env.VUE_APP_API_HOST + 'api/auth', {
      email: email,
      password: password
    }).then(response => {
      console.log(response)
      if (response.data.api_token !== null) {
        if (typeof success === 'function') {
          success(response.data)
        }
      } else {
        error(response.data)
      }
    }).catch(error => {
      error(error)
    })
  },

  logout () {
    return axios.delete('/auth/')
  }

})
