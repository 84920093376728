import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Storage from 'vue-ls'
import vuetify from './plugins/vuetify'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import Auth from '@/api/auth.js'
import User from '@/api/user.js'
import UserData from '@/api/userData.js'
import UserDataType from '@/api/userDataType.js'
import Department from '@/api/department.js'
import PlannedTimes from '@/api/plannedTimes.js'
import News from '@/api/news.js'
import AbsenceType from '@/api/absenceType.js'
import Absence from './api/absence.js'
import Role from './api/role.js'
import YearlySales from './api/yearlySales'
import Event from './api/event'
import Holiday from './api/holiday'

import './assets/styles/main.css'

dayjs.locale('de')
dayjs.extend(weekOfYear)

Vue.config.productionTip = false

const factories = {
  auth: Auth(axios),
  user: User(axios),
  userData: UserData(axios),
  userDataType: UserDataType(axios),
  department: Department(axios),
  plannedTimes: PlannedTimes(axios),
  news: News(axios),
  absenceType: AbsenceType(axios),
  absence: Absence(axios),
  role: Role(axios),
  yearlySales: YearlySales(axios),
  event: Event(axios),
  holiday: Holiday(axios)
}

Vue.prototype.$api = factories

const options = {
  namespace: 'schnitzler__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'session' // storage name session, local, memory
}

Vue.use(Storage, options)

new Vue({
  router,
  axios,
  VueAxios,
  vuetify,
  render: h => h(App)
}).$mount('#app')
