<template>
  <main id="login">
    <div id="login_background_container">
      <v-img
        src="../assets/img/login_background.jpg"
        position="top"
        cover
      ></v-img>
    </div>
    <div id="login_container">
      <div id="logo_container">
        <v-img
          src="../assets/img/Logo_Schnitzler.svg"
          height="60"
          position="center"
          contain
        ></v-img>
      </div>
      <div id="form_container">
          <input class="input" id="email" v-model="email" placeholder="E-Mail" type="email" />
          <input class="input" id="password" v-model="password" placeholder="Passwort" type="password" />

          <div class="button_container">
            <button @click="login()" class="button center fullwidth" >ANMELDEN</button>
          </div>
      </div>
    </div>
    <v-snackbar
      v-model="this.error"
      :timeout="5000"
      absolute
      bottom
      color="error"
      outlined
      top
    >
    {{ this.errorText }}
    </v-snackbar>
  </main>
</template>

<script>
export default {
  name: 'LoginView',
  components: {
  },
  data () {
    return {
      errorText: 'Diese E-Mail Adresse ist nicht bekannt, oder das Passwort ist falsch.',
      email: '',
      password: '',
      error: false
    }
  },
  methods: {
    login () {
      this.$emit('loadingStateChange', true)
      this.$api.auth.login(
        this.email,
        this.password,
        (data) => {
          console.log('Data:')
          console.log(data)
          this.$emit('loadingStateChange', false)
          if (typeof data !== 'undefined' && typeof data.surname !== 'undefined') {
            this.$ls.set('user_data', data)
            this.$emit('loginChange')
          } else {
            this.error = true
          }
        },
        () => {
          this.$emit('loadingStateChange', false)
          this.error = true
        }
      )
    }
  }
}
</script>
