<template>
  <v-avatar
    color="blue"
    size="50"
  ><span class="text-h5">{{ letters }}</span></v-avatar>
</template>

<script>

export default {
  name: 'PersonBatchComponent',
  props: {
    letters: String
  }
}
</script>
