<template>
  <div id="profile_top_bar">
    <div id="profile_dropup_container" tabindex="0" @focus="this.opendropup" @focusout="this.closedropup">
      <person-batch :letters="this.letters"/>
      <div v-if="this.dropup" class="dropup">
        <div class="element" @click="this.routeToProfile">
            Mein Profil
        </div>
        <div class="element" @click="this.logout">
          Abmelden
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonBatch from '../Addons/PersonBatch.vue'
export default {
  name: 'ProfileTopBarComponent',
  components: {
    PersonBatch
  },
  data () {
    return {
      letters: null,
      dropup: false
    }
  },
  created () {
    this.letters = this.$ls.get('user_data').givenname[0] + this.$ls.get('user_data').surname[0]
  },
  methods: {
    opendropup () {
      this.dropup = true
    },
    closedropup () {
      this.dropup = false
    },
    logout () {
      this.$router.push({ path: '/logout' })
    },
    routeToProfile () {
      this.$router.push({ path: '/profile' })
      this.closedropup()
      document.activeElement.blur()
    }
  }
}
</script>
