<template>
  <section id="menu_container">
    <div id="logo_container" @click="this.routeToHome">
      <v-img
        src="../../assets/img/Logo_Schnitzler.svg"
        height="60"
        contain
      ></v-img>
    </div>
    <menu>
      <router-link :to="{path: '/'}" exact>Startseite</router-link>
      <router-link :to="{path: '/my-schedule'}">Mein Dienstplan</router-link>
      <router-link v-if="departmentId !== null" :to="{path: `/work-schedule/${departmentId}`, arguments: { 'year': year, 'month': month}}">PEP</router-link>
      <router-link v-else :to="{path: `/work-schedule`}">PEP</router-link>
      <router-link v-if="departmentId !== null" :to="{path: `/absences/${departmentId}`, params: { departmentId: departmentId }}">Abwesenheiten (Urlaub/Krankheit)</router-link>
      <router-link v-else :to="{path: '/absences', params: { departmentId: departmentId }}">Abwesenheiten (Urlaub/Krankheit)</router-link>
      <router-link :to="{path: '/news'}">Neuigkeiten</router-link>
      <router-link :to="{path: '/personnel-administration'}">Personalverwaltung</router-link>
      <router-link :to="{path: '/department-administration'}">Abteilungsverwaltung</router-link>
    </menu>
    <div id="version_code">
      Version: {{ version }}
    </div>
  </section>
</template>

<script>
// import { useRoute } from 'vue-router'
export default {
  name: 'MenuComponent',
  components: {
  },
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      departmentId: null,
      year: 2024,
      month: null
    }
  },
  methods: {
    routeToHome () {
      this.$router.push({ path: '/' })
    }
  },
  watch: {
    $route () {
      if (typeof this.$route.params.departmentId !== 'undefined') {
        this.departmentId = Number(this.$route.params.departmentId)

        console.log('ROUTE', this.$route)
      }
    }
  }
}
</script>
