export default axios => ({
  get (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/event?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  add (departmentId, date, text, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.date = date
    data.text = text

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/event',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (eventId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/event/' + eventId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (eventId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/event/' + eventId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }
})
