export default axios => ({
  get (startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/holiday?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }
})
