export default axios => ({

  users (apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }
    axios.get(process.env.VUE_APP_API_HOST + 'api/user').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  user (userId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }
    axios.get(process.env.VUE_APP_API_HOST + 'api/user/' + userId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (userId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }
    axios.put(
      process.env.VUE_APP_API_HOST + 'api/user/' + userId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  generateNewPassword (userId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }
    axios.put(
      process.env.VUE_APP_API_HOST + 'api/user/' + userId + '/generateNewPassword'
    ).then(response => {
      callback(response.data)
    }).catch(() => {
      callback()
    })
  },

  add (surname, givenname, email, password, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.surname = surname
    data.givenname = givenname
    data.email = email
    data.password = password

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/user',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (userId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/user/' + userId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }
})
