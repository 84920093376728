export default axios => ({

  get (roleId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/role/' + roleId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getAll (apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/role').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  add (data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/role',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (roleId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/role/' + roleId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (roleId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/role/' + roleId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
