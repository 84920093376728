export default axios => ({

  get (newsId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/news/' + newsId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getAll (apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/news').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getAllPublic (apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/news/public').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  add (data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/news',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (newsId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/news/' + newsId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (newsId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/news/' + newsId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
