export default axios => ({

  get (departmentId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getAll (apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  add (name, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.name = name

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/department',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (departmentId, name, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.name = name

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/department/' + departmentId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (departmentId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getUsers (departmentId, chief, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    chief = (chief) ? 1 : 0
    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/user?chief=' + chief).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getAllUsers (departmentId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/user').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  addUser (departmentId, userId, chief, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    chief = (chief) ? 1 : 0
    axios.post(
      process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/user/' + userId,
      {
        chief: chief
      }
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  deleteUser (departmentId, userId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/user/' + userId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
