export default axios => ({

  get (userDataTypeId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/userDataType/' + userDataTypeId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  getAll (apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/userDataType').then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }

})
