export default axios => ({
  get (departmentId, startDate, endDate, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.get(process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/yearlySale?startDate=' + startDate + '&endDate=' + endDate).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  add (departmentId, date, sales, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    const data = {}
    data.date = date
    data.sales = sales

    axios.post(
      process.env.VUE_APP_API_HOST + 'api/department/' + departmentId + '/yearlySale',
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  update (yearlySaleId, data, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.put(
      process.env.VUE_APP_API_HOST + 'api/yearlySale/' + yearlySaleId,
      data
    ).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  },

  delete (yearlySaleId, apiToken, callback) {
    axios.defaults.headers.common = {
      'API-TOKEN': apiToken
    }

    axios.delete(process.env.VUE_APP_API_HOST + 'api/yearlySale/' + yearlySaleId).then(response => {
      callback(response)
    }).catch(() => {
      callback()
    })
  }
})
